








































































































































































































































































import { Component, Vue, Prop, Watch, Provide } from "vue-property-decorator";
import { Form as ElForm } from "element-ui";
import { addStore, updatStore } from "@/remote/store";
import { IStoreModel } from "./../data/storeModel";
import ImgUpload from "@components/ImgUpload.vue";
import { provinceAndCityData, codeToText } from "element-china-area-data";
import moment from "moment";
interface IBaseBoundToModel {
  id: string;
  name: string;
}

declare var qq: any;

// 装饰器模式
@Component({
  components: {
    ElForm,
    ImgUpload,
  },
})
export default class extends Vue {
  // 显示或隐藏
  @Prop({
    default: false,
  })
  dialogFormVisible!: boolean;

  @Prop() storeModel!: IStoreModel;
  provinceAndCityData = provinceAndCityData;
  selectedOptions: any;

  // Data
  formLabelWidth = "140px";
  saveBtnLoading = false;
  formParams: IStoreModel = {
    id: "",
    type: "",
    name: "",
    description: "",
    summary: "",
    coverImage: [],
    listImage: [],
    detailImage: [],
    bannerImages: [],
    city: "",
    openingTimeTo: "",
    openingTimeFrom: "",
    address: {
      address: "",
      navigationAddress: "",
      geoCoordinate: {
        latitude: 0,
        longitude: 0,
      },
    },
    openingHours: "",
    // phoneNumber: "",
    redemptionCode: "",
    isPublished: false,
    isEverPublished:false,
  };
  qqMap: any;
  searchService: any;
  loading = false;
  poiAddr: any[] = [];
  selectPoi: any = null;
  mark: any = null;
  value = "";
  type = "popup";
  formValueWidth: number = 450;
  get uploadImageItemWidth() {
    return `${(this.formValueWidth - 20) / 5}px`;
  }

  callBack: any;
  validatNavAddr = (rule: any, value: string, callback: Function) => {
    console.log("(window as any).pagethis");
    setTimeout(() => {
      var _this = (window as any).pagethis;
      console.log(this);
      if (_this.formParams.address.geoCoordinate != null) {
        callback();
      } else {
        callback(new Error("请输入导航地址"));
      }
    }, 500);
  };

  validateOpeningTime = (rule: any, value: any, callback: Function) => {
    setTimeout(() => {
      var _this = (window as any).pagethis;
      console.log(this);
      if (
        _this.formParams.openingTimeTo != "" &&
        _this.formParams.openingTimeFrom != ""
      ) {
        callback();
      } else {
        callback(new Error("请输入导航地址"));
      }
    }, 500);
  };
  validataddr = (rule: any, value: any, callback: Function) => {
    console.log("validataddr", value);
    if (value.address === "" || value.address === null) {
      callback(new Error("请输入地址"));
    } else {
      callback();
    }
  };
  // Watch
  @Provide()
  rules = {
    name: [
      {
        required: true,
        message: "请输入名称",
        trigger: "blur",
      },
    ],
    navigationAddress: [
      {
        required: true,
        message: "请输入导航地址",
        trigger: "blur",
        validator: this.validatNavAddr,
      },
    ],
    address: [
      {
        required: true,
        message: "请输入地址",
        trigger: "blur",
        validator: this.validataddr,
      },
    ],
    openingHours: [
      {
        required: true,
        message: "请输入营业时间",
        trigger: "blur",
      },
    ],
    // phoneNumber: [
    //   {
    //     required: true,
    //     message: "请输入咨询电话",
    //     trigger: "blur",
    //   },
    // ],
    // summary: [
    //   {
    //     required: true,
    //     message: "请输入简介",
    //     trigger: "blur",
    //   },
    // ],
    description: [
      {
        required: true,
        message: "请输入简介",
        trigger: "blur",
      },
    ], 
    coverImage: [
      {
        required: true,
        message: "请输入首页封面图",
        trigger: "blur",
      },
    ],
    listImage: [
      {
        required: true,
        message: "请输入列表页展示图",
        trigger: "blur",
      },
    ],
    detailImage: [
      {
        required: true,
        message: "请输入详情页长图",
        trigger: "blur",
      },
    ],
    bannerImages: [
      {
        required: true,
        message: "请输入详情页轮播图",
        trigger: "blur",
      },
    ],
    openingTimeTo: [
      {
        required: true,
        message: "请输入开放时间",
        trigger: "blur",
      },
    ],
  };

  @Watch("storeModel", {
    immediate: true,
    deep: true,
  })
  onBannerModelChange(value: IStoreModel) {
    if (!value) return;
    const model = JSON.parse(JSON.stringify(value));

    this.formParams = model;
    console.log(this.formParams);
    this.timeRanges = [
      this.formParams.openingTimeFrom,
      this.formParams.openingTimeTo,
    ];
    console.log(this.timeRanges);

    this._setSelectCity();
    console.log(this.selectAddr);
    // this.formParams = {
    //   id: model.id,
    //   name: model.name,
    //   type: model.type,
    //   description: model.description,
    //   summary: model.summary,
    //   coverImage: model.coverImage,
    //   listImage: model.listImage,
    //   detailImage: model.detailImage,
    //   bannerImages: model.bannerImages,
    //   city: model.city,
    //   navAddr: model.navAddr,
    //   address: model.address,
    //   openingTime: model.openingTime,
    //   openingHours: model.openingHours,
    //   phoneNumber: model.phoneNumber,
    //   isPublished: model.isPublished,
    // };
  }
  _setSelectCity() {
    for (var i = 0; i < provinceAndCityData.length; i++) {
      for (var j = 0; j < provinceAndCityData[i].children!.length; j++) {
        if (
          provinceAndCityData[i].children![j].label === this.formParams.city
        ) {
          return (this.selectAddr = [
            provinceAndCityData[i].value,
            provinceAndCityData[i].children![j].value,
          ]);
        }
      }
    }
  }
  setMap() {
    (window as any).pagethis = this;
    let center = new qq.maps.LatLng(39.916527, 116.397128);
    if (this.storeModel) {
      center = new qq.maps.LatLng(
        this.storeModel.address.geoCoordinate.latitude,
        this.storeModel.address.geoCoordinate.longitude
      );
    }
    var myOptions = {
      center,
      mapTypeControl: false,
      disableDoubleClickZoom: false,
      scaleControl: false,
      panControl: false,
      zoomControl: false,
      zoom: 13, //设置地图缩放级别
      mapTypeId: qq.maps.MapTypeId.ROADMAP, //设置地图样式详情参见MapType
    };
    //获取dom元素添加地图信息
    this.qqMap = new qq.maps.Map(
      document.getElementById("container"),
      myOptions
    );
    if (this.storeModel) {
      this.mark = new qq.maps.Marker({
        position: center,
        map: this.qqMap,
      });
    }
    this.searchService = new qq.maps.SearchService({
      //检索成功的回调函数
      complete: (results: any) => {
        //设置回调函数参数
        this.loading = false;
        if (results.type === "POI_LIST") {
          this.poiAddr = results.detail.pois;
          this.callBack(this.poiAddr);
        }
      },
      //若服务请求失败，则运行以下函数
      error: () => {
        // console.log("出错了。");
        this.selectPoi = null;
        this.poiAddr = [];
        this.loading = false;
        this.callBack(this.poiAddr);
      },
    });
  }
  querySearch(queryString: string, cb: Function) {
    console.log(queryString);
    // this.formParams.city=queryString.substring(0, queryString.indexOf('市')+1)
    // console.log( this.formParams.city)
    this.callBack = cb;
    if (queryString !== "") {
      this.loading = true;
      if (this.searchService) {
        let pos = queryString.indexOf("市");
        if (pos != -1) {
          let keyWord = queryString.substring(pos + 1, queryString.length);
          let city = queryString.substring(0, pos);
          console.log(keyWord + " _" + city);
          this.searchService && this.searchService.setPageCapacity(1000);
          this.searchService.setLocation(city);
          this.searchService.search(keyWord);
        } else {
          this.loading = false;
          this.selectPoi = null;
          this.poiAddr = [];
          this.callBack(this.poiAddr);
        }
      }
    } else {
      this.selectPoi = null;
      this.poiAddr = [];
      this.loading = false;
      this.callBack(this.poiAddr);
    }
  }
  remoteMethod(queryString: string) {
    // console.log(queryString)
    if (queryString !== "" && queryString != undefined) {
      this.loading = true;
      if (this.searchService) {
        let pos = queryString.indexOf("市");
        if (pos != -1) {
          let keyWord = queryString.substring(pos + 1, queryString.length);
          let city = queryString.substring(0, pos);
          // console.log(keyWord + " _" + city);
          this.searchService && this.searchService.setPageCapacity(1000);
          this.searchService.setLocation(city);
          this.searchService.search(keyWord);
        } else {
          this.loading = false;
          this.selectPoi = null;
          this.poiAddr = [];
        }
      }
    } else {
      this.selectPoi = null;
      this.poiAddr = [];
      this.loading = false;
    }
  }
  handleSelect(item: any) {
    this.selectPoi = item;
    this.formParams.address.navigationAddress = item.name;
    this.formParams.address.geoCoordinate.latitude = item.latLng.lat;
    this.formParams.address.geoCoordinate.longitude = item.latLng.lng;
    var myLatlng = new qq.maps.LatLng(item.latLng.lat, item.latLng.lng);
    if (this.qqMap) {
      this.qqMap.panTo(myLatlng);
    }

    if (this.mark === null) {
      this.mark = new qq.maps.Marker({
        position: myLatlng,
        map: this.qqMap,
      });
      //设置Marker的动画属性为从落下
      this.mark.setAnimation(qq.maps.MarkerAnimation.DOWN);
      //设置Marker是否可以被拖拽，为true时可拖拽，false时不可拖拽，默认属性为false
      // this.mark.setDraggable(true);
      let that = this;
      //设置Marker停止拖动事件
      qq.maps.event.addListener(this.mark, "dragend", function () {
        console.log(that.mark.getPosition());
      });
    } else {
      this.mark.moveTo(myLatlng);
    }
  }
  _onSelectChange(value: any) {
    if (this.poiAddr && this.poiAddr.length > 0) {
      for (var i = 0; i < this.poiAddr.length; i++) {
        if (this.poiAddr[i].id === value) {
          this.selectPoi = this.poiAddr[i];
          var myLatlng = new qq.maps.LatLng(
            this.poiAddr[i].latLng.lat,
            this.poiAddr[i].latLng.lng
          );
          if (this.qqMap) {
            this.qqMap.panTo(myLatlng);
          }

          if (this.mark === null) {
            this.mark = new qq.maps.Marker({
              position: myLatlng,
              map: this.qqMap,
            });
          } else {
            this.mark.moveTo(myLatlng);
          }
          break;
        }
      }
    }
  }

  _onInput(value: any, type: string) {
    //  console.log(type);
    // console.log(value);
    // var value = event.target.value.trimStart();
    var temp = 0;
    var maxLen = type === "name" ? 20 : 16;
    if (value.length === 0 && type === "name") {
      // this.titleLength = 0;
    } else if (value.length === 0 && type === "subTitle") {
      // this.subTitleLength = 0;
    } 

    for (var i = 0; i < value.length; i++) {
      if (/[\u4e00-\u9fa5]/.test(value[i])) {
        temp += 2;
      } else {
        temp++;
      }
      if (temp > maxLen) {
        // @ts-ignore
        value = value.substr(0, i).trimStart();
      }
    }
    if (type === 'redemptionCode') {
      // this.formParams.redemptionCode = value;
      // this.titleLength = temp > maxLen ? maxLen : temp;
    } else {
      // this.formParams.subTitle = value;
      // this.subTitleLength = temp > maxLen ? maxLen : temp;
    }
  }
  // Methods
  mounted() {
    console.log("mounted 3", this.$route.path);
    console.log(this.provinceAndCityData);
    this.provinceAndCityData.forEach((element: any) => {
      if (element.label.indexOf("市") != -1) {
        element.children = [
          {
            label: element.label,
            value: element.value,
          },
        ];
      }
    });
    console.log(this.provinceAndCityData);
    if (this.$route.path != "/PopUpList") {
      this.type = "flagship";
      //rules规则检测
      this.formParams.openingTimeTo="20230505"
    }
    setTimeout(() => {
      this.setMap();
    }, 1000);
  }

  _beforeModalClose() {
    this.$emit("update:dialogFormVisible", false); // 直接修改父组件的属性
  }

  _hide() {
    this.$emit("update:dialogFormVisible", false);
  }

  _onClickConfirm() {
    const form = this.$refs["galleryForm"] as ElForm;
    form.validate((valid: Boolean) => {
      if (valid) {
        this._showConfirmToast();
      } else {
        return false;
      }
    });
  }

  timeRanges: string[] = [moment().format("YYYY-MM-DD"), ""];
  unlimitPickerOptions = {
    disabledDate(time: Date) {
      return moment(time).isBefore(moment().subtract(1, "d"));
    },
  };
  _onChangeTimeRange(event: any) {
    console.log(event);
    this.formParams.openingTimeFrom = moment(event[0]).format("YYYY-MM-DD");
    this.formParams.openingTimeTo = moment(event[1]).format("YYYY-MM-DD");
  }
  _showConfirmToast() {
    const that = this;
    this.$confirm(
      this.storeModel ? "确认更新"+(this.type==='popup'?'快闪店':'合作餐吧')+"吗？" : "确认保存为新的"+(this.type==='popup'?'快闪店':'合作餐吧')+"吗？",
      "提示",
      {
        confirmButtonText: this.storeModel ? "更新" : "保存",
        cancelButtonText: "取消",
        center: true,
      }
    )
      .then(() => {
        // console.log(that.selectPoi);
        // if (that.selectPoi != null) {
        //   that.formParams.address.geoCoordinate.latitude =
        //     that.selectPoi.latLng.lat;
        //   that.formParams.address.geoCoordinate.longitude =
        //     that.selectPoi.latLng.lng;
        // }
        let reqData: any = that.formParams;
        if (this.type != "popup") {
          const { openingTimeTo, openingTimeFrom, ...rest } = that.formParams;
          reqData = rest;
        }
        const {redemptionCode,...rest}=reqData
         reqData = rest;
        if (this.storeModel) {
          that._updateBar(reqData);
        } else {
          reqData.type = this.type; 
          that._createBar(reqData);
        }
      })
      .catch(() => {});
  }

  _onClickCancel() {
    this.$confirm("", "确认放弃编辑吗？", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      center: true,
    })
      .then(() => {
        this._hide();
      })
      .catch(() => {});
  }

  // 新增
  async _createBar(reqModel: IStoreModel) {
    this.saveBtnLoading = true;
    try {
      const res = await addStore(reqModel);
      if (res.status === 200) {
        this.$message.success("保存成功");
        this.$emit("success");
        this._hide();
      }
      this.saveBtnLoading = false;
    } catch {
      this.saveBtnLoading = false;
      // console.log("createBar");
    }
  }

  // 编辑
  async _updateBar(reqModel: IStoreModel) {
    // console.log("_updateBar");
    this.saveBtnLoading = true;
    try {
      const res = await updatStore(reqModel.id, reqModel);
      if (res.status === 200) {
        this.$message.success("保存成功");
        this.$emit("success");
        this._hide();
      }
      this.saveBtnLoading = false;
    } catch {
      this.saveBtnLoading = false;
      // console.log("_updateBar 出错了");
    }
  }
  selectAddr: any = [];
  cityChange(obj: any) {
    this.formParams.city = codeToText[obj[1]];
    console.log(this.selectAddr);
  }
}
