

































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import moment from "moment";

import Pagination from "@/components/pagination/Pagination.vue";

import { Table as ElTable } from "element-ui";
import Sortable from "sortablejs"; // 用于拖拽排序

import { IPanelData } from "@/data/commonModel";
import { UserModule } from "@/store/modules/user";
import { StorerModule } from "@/store/modules/store";
import {
  IStoreModel,
  IStoreSortReqModel,
  IStoreListQuery,
} from "./../data/storeModel";
import {
  getStores,
  deleteStore, 
  updateStoreListSort,
  publish,
  reportCampaign
} from "@/remote/store";
import { IBannerListQuery } from "@/pages/banner/data/bannerModel";
import { addClass, removeClass } from "@/utils/tools";

// 装饰器模式
@Component({
  components: {
    Pagination,
  },
})
export default class extends Vue {
  // Props
  @Prop({
    required: true,
  })
  panelObj!: IPanelData;
  // 开始/取消前端显示排序
  @Prop({ default: false })
  isOrdering!: boolean;

  listLoading: boolean = false;
  allList: IStoreModel[] = [];
  list: IStoreModel[] = [];
  totalSize = 0;
  listQuery: IStoreListQuery = {
    type: "all",
    updatedTime: "",
  };
  sortable: any = null;

  // Watch
  @Watch("panelObj", {
    deep: true,
    immediate: true,
  })
  _onPanelObjChange(value: IPanelData) {
    this.listQuery.type = value.type;
    // console.log("_onPanelObjChange");
    // console.log(value);
    if (value.type == "all") {
      this.list = this.allList;
    } else {
      let tempList: IStoreModel[] = [];
      this.allList.forEach((element: IStoreModel) => {
        if (
          (element.isPublished && value.type === "published") ||
          (!element.isPublished && value.type === "unpublished")
        ) {
          tempList.push(element);
        }
      });

      this.list = tempList;
    }
    // console.log(this.list);
  }

  @Watch("isOrdering", {
    immediate: true,
  })
  onIsOrderingChange(value: boolean) {
    if (this.sortable) {
      this.sortable.option("disabled", !value);
    }
    if (value && this.listQuery.updatedTime !== "") {
      // console.log("清除创建、发布时间排序");
      // 清除创建、发布时间排序
      this.listQuery.updatedTime = "";
      const table = this.$refs["main-banner-table"] as ElTable;
      table.clearSort();
      this.refreshTable();
    } else if (this.sortable && !value && this.listQuery.type === "published") {
      // 保存排序
      this._updateTableSort();
    }
  }
  type = "popup";
  // Methods
  mounted() {
    console.log("mounted 2", this.$route.path);
    this._rowDrop();
    if (this.$route.path != "/PopUpList") {
      this.type = "flagship";
    }
    this._fetchStores();
  }

  //行拖拽
  _rowDrop() {
    const tbody = document.querySelector(".el-table__body-wrapper tbody");
    const _this = this;
    if (!tbody) return;
    this.sortable = new Sortable(tbody, {
      disabled: true,
      onStart: () => {
        addClass(".el-table__body-wrapper tbody", "cursor-move");
        removeClass(".el-table__body-wrapper tbody", "cursor-default");
      },
      onEnd: (event: any) => {
        const { newIndex, oldIndex } = event;
        const currRow = _this.list.splice(oldIndex, 1)[0]; // 删除
        _this.list.splice(newIndex, 0, currRow); // 添加到指定位置
        removeClass(".el-table__body-wrapper tbody", "cursor-move");
        addClass(".el-table__body-wrapper tbody", "cursor-default");
      },
    });
  }

  public refreshTable() {
    this._fetchStores();
  }

  async _fetchStores() {
    this.listLoading = true;
    try {
      const res = await getStores(this.type);
      if (res && res.data && res.data.data) {
        const { data } = res.data;
        let publishCount = 0;
        data.forEach((element: IStoreModel) => {
          if (element.isPublished) {
            publishCount++;
          }
          element.openingTimeFrom = moment(element.openingTimeFrom).format(
            "YYYY-MM-DD"
          );
          element.openingTimeTo = moment(element.openingTimeTo).format(
            "YYYY-MM-DD"
          );
          element.coverImage = [{ path: element.coverImage }];
          element.listImage = [{ path: element.listImage }];
          element.detailImage = [{ path: element.detailImage }];
          if (element.bannerImages) {
            const arr: any = [];
            element.bannerImages.forEach((image: string) => {
              arr.push({ path: image });
            });
            element.bannerImages = arr;
          } else {
            element.bannerImages = [];
          }
        });
        this.allList = this.list = data;
        // console.log("publishCount = " + publishCount);
        StorerModule.SET_STORE_COUNET_RES({
          allCount: this.list.length,
          publishedCount: publishCount,
          unpublishedCount: data.length - publishCount,
        });
        this._onPanelObjChange(this.panelObj);
      } else {
        this.list = [];
      }
    } catch (error) {
      // console.log(error);
      // console.log("_fetchStores 出错了");
    }
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  async _onClickEdit(id: number) {
    // console.log(this.list[id]);
    try {
      this.$emit("onEditBar", this.list[id]);
    } catch {
      // console.log("getViceBannerDetails 失败了");
    }
  }

  _onChangeSwitch(value: boolean, store: IStoreModel, index: number) {
    if (value) {
      if (store.isEverPublished&&value) {
        store.isPublished = false;
        this.$message.error("下架后不能重新上架。");
        return;
      }
    }
    this.$confirm(
      "",
      value
        ? "确认发布这个" +
            (this.type === "popup" ? "快闪店" : "合作餐吧") +
            "吗？"
        : "确认取消发布吗？",
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        center: true,
      }
    )
      .then(() => {
        this._onUpdatePublishStatus(value, store, index);
      })
      .catch(() => {
        this.list[index].isPublished = !value;
      });
  }

  async _onUpdatePublishStatus(
    value: boolean,
    store: IStoreModel,
    index: number
  ) {
    try {
      this.listLoading = true;
      const isPublished = value;
      store.isPublished = value;
      let reqData: any = store;
      if (this.type != "popup") {
        const { openingTimeTo, openingTimeFrom, ...rest } = store;
        reqData = rest;
      }
      const res = await publish(store.id, isPublished);
      this.listLoading = false;
      if (res.data.resultCode === 0) {
        // if (this.listQuery.type === 'all') {
        //   this.list[index].isPublished = value
        // } else {
        this.refreshTable();
        // }
        this.$message.success("操作成功");
      }
    } catch {
      this.listLoading = false;
    }
  }

  // 删除
  async _onClickDelete(id: string) {
    this.$confirm(
      "",
      "确认删除这个" + (this.type === "popup" ? "快闪店" : "合作餐吧") + "吗？",
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        center: true,
      }
    )
      .then(() => {
        this._deleteStore(id);
      })
      .catch(() => {});
  }

  async _deleteStore(id: string) {
    try {
      this.listLoading = true;
      const res = await deleteStore(id);
      this.listLoading = false;
      this._fetchStores();
    } catch {
      this.listLoading = false;
    }
  }

  // 更新展示排序
  async _updateTableSort() {
    const req: IStoreSortReqModel = { ids: [] };
    this.list.forEach((element: IStoreModel, index: number) => {
      req.ids.push(element.id);
    });
    try {
      this.listLoading = true;
      const res = await updateStoreListSort(req);
      if (res && res.status === 200) {
        this.$message.success("保存成功");
      } else {
        this.$message.error("保存失败，请重试");
      }
      this.listLoading = false;
    } catch {
      this.listLoading = false;
      this.$message.error("保存失败，请重试");
      // console.log("_updateTableSort 出错了");
    }
  }
  onCopy(code: string) {
    const save = function (e: any) {
      e.clipboardData.setData("text/plain", code);
      e.preventDefault(); // 阻止默认行为
    };
    document.addEventListener("copy", save); // 添加一个copy事件
    document.execCommand("copy"); // 执行copy方法
    this.$message({ message: "复制成功", type: "success" });
  }
  exportButtonLoading: boolean = false;
  async downReport(id:string){
this.exportButtonLoading = true;
    try {
      const res = await reportCampaign(id);
      if (!res || !res.data) return;
      let a = document.createElement("a");
      let blob = new Blob([res.data], {
        type: "application/octet-stream", //这里放文件类型，后台返回的response会有这个文件类型，放进来就行
      });
      a.href = URL.createObjectURL(blob);
      a.download = "活动名单.xlsx";
      a.click();
      this.exportButtonLoading = false;
    } catch {
      this.exportButtonLoading = false;
      this.$message.error("导出失败，请重试");
      // console.log("exportCustomList 出错了");
    }
  }
}
