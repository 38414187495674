import http from './interface'
import { UserModule } from '@/store/modules/user'
import { IStoreModel,IStoreSortReqModel,IStoreCouponListQuery } from '@/pages/store/data/storeModel' 

export const getStoreCountByStatus = () => {
  return http.get(`/api/v3/stores/count`)
} 

export const getStores = (type:string)=> { 
  return http.get('/api/v3/stores?type='+type)
}

export const getAllStores = ()=> { 
  return http.get('/api/v3/stores')
}

export const getAllPublishStores = ()=> { 
  return http.get('/api/v3/stores?isPublished=true')
}

export const getPopupStores = ()=> { 
  return http.get('/api/v3/stores?type=popup')
}
export const getFlagShipStores = ()=> { 
  return http.get('/api/v3/stores?type=flagship')
}
 

// 删除
export const deleteStore = (storeId: string) => {
  return http.delete(`/api/v3/stores/${storeId}`)
}
 
// 新增

export const addStore = (data: IStoreModel) => { 
  const { coverImage,listImage,detailImage,bannerImages, ...rest } = data;
  const arr:any=[]
  bannerImages.forEach((element:any)=>{
    if(element.path.startsWith("http")){ 
      arr.push(element.path)
    }else{ 
      arr.push(process.env.VUE_APP_BASE_API+"/"+element.path)
    }
  })
  return http.post(`/api/v3/stores`, {
    ...rest,
    coverImage:process.env.VUE_APP_BASE_API+"/"+coverImage[0].path,
    listImage:process.env.VUE_APP_BASE_API+"/"+listImage[0].path,
    detailImage:process.env.VUE_APP_BASE_API+"/"+detailImage[0].path,
    bannerImages:arr, 
  })
}

// 修改
export const updatStore = (storeId: string, data: IStoreModel) => {
  
  const { coverImage,listImage,detailImage,bannerImages, ...rest } = data;
  let newCoverImageUrl,newListImage,newDetailImage
  let newBannerImages:any=[] 
  if(coverImage[0].path.startsWith("http")){
    newCoverImageUrl=coverImage[0].path
  }else{
    newCoverImageUrl=process.env.VUE_APP_BASE_API+"/"+coverImage[0].path
  } 
  if(listImage[0].path.startsWith("http")){
    newListImage=listImage[0].path
  }else{
    newListImage=process.env.VUE_APP_BASE_API+"/"+listImage[0].path
  } 
  if(detailImage[0].path.startsWith("http")){
    newDetailImage=detailImage[0].path
  }else{
    newDetailImage=process.env.VUE_APP_BASE_API+"/"+detailImage[0].path
  } 
  bannerImages.forEach((element:any)=>{
    if(element.path.startsWith("http")){
      newBannerImages.push(element.path)
    }else{
      newBannerImages.push(process.env.VUE_APP_BASE_API+"/"+element.path)
    }
  })
  
  
  return http.patch(`/api/v3/stores/${storeId}`, {
    ...rest,
    coverImage:newCoverImageUrl,
    listImage:newListImage,
    detailImage:newDetailImage,
    bannerImages:newBannerImages,
  })
}
 
export const publish=(storeId:string,isPublished:boolean)=>{
  return http.patch(`/api/v3/stores/${storeId}/publish`,{isPublished})
}

export const updateStoreListSort = (list: IStoreSortReqModel) => {
  return http.post(`/api/v3/stores/sort`, list)
}
 
export const reportCampaign = (storeId: string) => {
  return http.get(`/api/v3/stores/${storeId}/report`,{
    responseType: "blob", //告诉服务器我们需要的响应格式
  })
}