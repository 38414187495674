







































import { Component, Vue } from "vue-property-decorator";

import PanelGroup from "@/components/PanelGroup.vue";
import StoreTable from "./components/StoreTable.vue";
import StoreEdit from "./components/StoreEdit.vue";

import { ITabModel, IPanelData } from "@/data/commonModel";
import { IStoreModel } from "./data/storeModel.d";
import { UserModule } from "@/store/modules/user";
import { StorerModule } from "@/store/modules/store";

// 装饰器模式
@Component({
  components: {
    PanelGroup,
    StoreTable,
    StoreEdit, 
  },
})
export default class extends Vue {
  // Data
  editDialogVisible: boolean = false;
  isOrdering: boolean = false; // 是否在排序中
  editType: string = "add";
  panelObj: IPanelData = { type: "all", searchText: "" ,show:false};
  storeModel: IStoreModel | null = null;


 
  // Get
  get tabs(): ITabModel[] {
    const arr = [
      {
        title: `全部(${StorerModule.storeCountRes.allCount})`,
        key: "all",
      },
      {
        title: `已发布(${StorerModule.storeCountRes.publishedCount})`,
        key: "published",
      },
      {
        title: `未发布(${StorerModule.storeCountRes.unpublishedCount})`,
        key: "unpublished",
      },
    ];
    return arr;
  }
  // Methods
 private mounted() {
console.log("mounted 1",this.$route.path);
  }

  // 切换选项卡
  _handleSwitchTab(props: IPanelData) {
    this.panelObj = props;
    this.isOrdering = false;
  }

  // 新建活动
  _onClickAdd() {
    // if (StorerModule.storeCountRes.allCount >= 5) {
    //   this.$message.error("您只能创建5个快闪店");
    //   return;
    // }
    this.editType = "add";
    this.storeModel = null;
    this.editDialogVisible = true;
  }

  // 开始/取消对MP展示进行排序
  _onClickSort() {
    // if (!UserModule.isMain) {
    //   this.$message.error("需在上海门店界面才可管理快闪店");
    //   return;
    // }
    this.isOrdering = !this.isOrdering;
  }

  _handleEditStoreSuccess(type: string) {
    const ref: StoreTable = this.$refs["store-table"] as StoreTable;
    ref.refreshTable();
  }

  _handleEditBar(value: IStoreModel) {
    this.editType = "edit";
    this.editDialogVisible = true;
    this.storeModel = JSON.parse(JSON.stringify(value));
  }
}
